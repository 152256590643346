import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import RegistrationFrom from '../../components/registration-form/registration-from';
import SEO from '../../components/seo';
import withLocation from '../../components/withLocation';

const Register = ({ search, navigate }) => {
  const { q } = search;
  const [source, setSource] = useState('Website');
  useEffect(() => {
    if (q && q.length > 0) {
      const _allowed = [
        'instagram',
        'linkedin',
        'facebook',
        'youtube',
        'gs',
        'gdn',
        'ns',
        'twitter',
        'tiktok',
        'sap',
        'etisalat',
        'tgc',
        'dewa',
        'adq',
        'fab',
        'etihad-rail',
        'ega',
        'ge',
        'schneider',
        'edb',
        'sberbank',
        'enec',
        'mubadala',
        'thales',
        'adnoc',
        'guest-invites',
        'speaker',
        'g42',
        'makingprosperity',
        'rta',
        'workinggroup',
        'microsoft',
        'ICTFUND',
        'EcGdn',
        'EcArabianBusiness',
        'EcTheNationalPg',
        'EcTheNational',
        'EcAlBayan',
        'EcEmaratAlYoum',
        'EcGulfNews',
        'EcAlWatan',
        'EcTwitter',
        'EcLinkedin',
        'EcGs',
        'EcUaeBarq',
        'EcForbes',
        'F6s',
        'insead',
        'sedd',
        'uae-gov',
        'whatsapp',
      ];
      if (_allowed.includes(q)) setSource(q);
      else navigate('/registration');
    }
  }, []);
  return (
    <Layout>
      <SEO lang='en' title='Registration | Global Manufacturing & Industrialisation Summit | GMIS 2024' />
      <RegistrationFrom source={source} page='main' />
    </Layout>
  );
};

Register.propTypes = {
  search: PropTypes.object,
};
export default withLocation(Register);
